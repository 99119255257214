import { call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import * as API from '../../coachApp/services/eventAPI';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import * as actionTypes from './actionTypes';
import {
  eventsFail,
  eventsLoaded,
  eventsLoading,
  getEventsSuccess,
  createEventSuccess,
  deleteEventSuccess,
  updateEventSuccess,
} from './reducers';
import * as types from './types';
import * as clientAPI from '../../clientApp/services/events';

export function* getEvents(): any {
  try {
    yield put(eventsLoading());
    const response: SagaReturnType<typeof API.getEvents> = yield call(API.getEvents);
    yield put(getEventsSuccess(response));
    yield put(eventsLoaded());
  } catch (error) {
    yield put(eventsFail(error));
  }
}

export function* getClientEvents(): any {
  try {
    yield put(eventsLoading());
    const response: SagaReturnType<typeof API.getEvents> = yield call(clientAPI.getEvents);
    yield put(getEventsSuccess(response));
    yield put(eventsLoaded());
  } catch (error) {
    yield put(eventsFail(error));
  }
}

export function* createEventSaga({ event }: types.CreateEventRequest) {
  try {
    yield put(eventsLoading());
    const response: SagaReturnType<typeof API.createEvent> = yield call(API.createEvent, event);
    yield put(createEventSuccess(response));
    yield put(eventsLoaded());
  } catch (error: any) {
    yield put(eventsFail(error));
  }
}
export function* updateEventSaga({ event }: types.UpdateEventRequest) {
  try {
    yield put(eventsLoading());
    const response: SagaReturnType<typeof API.createEvent> = yield call(API.updateEvent, event);
    yield put(updateEventSuccess(response));
  } catch (error: any) {
    yield put(eventsFail(error));
  }
}
export function* deleteEventSaga({ id }: types.DeleteEventRequest) {
  try {
    yield put(eventsLoading());
    const response: SagaReturnType<typeof API.deleteEvent> = yield API.deleteEvent(id);
    yield put(deleteEventSuccess(id));
  } catch (error: any) {
    yield put(eventsFail(error));
  }
}
export function* eventSaga() {
  yield takeLatest(actionTypes.GET_EVENTS, getEvents);
  yield takeLatest(actionTypes.CREATE_EVENT_REQUEST, createEventSaga);
  yield takeLatest(actionTypes.UPDATE_EVENT_REQUEST, updateEventSaga);
  yield takeLatest(actionTypes.DELETE_EVENT_REQUEST, deleteEventSaga);
  yield takeLatest(actionTypes.GET_CLIENT_EVENTS, getClientEvents);
}
