import * as paths from './paths';
import { EventBase } from '../../tsUtils';
import * as coachPaths from '../../coachApp/services/paths';


export async function getEvents(): Promise<EventBase[]> {
  let endpoint = `${paths.CLIENTEVENTS}`;
  let headers = coachPaths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}