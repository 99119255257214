import React, { useCallback, useEffect } from 'react';
import '../../../style/Coach/Session.scss';
import { Button, debounce, Fab, TextField, Typography } from '@mui/material';
import { AccountType, Definition, ExerciseCardClass, SessionInterface } from '../../../tsUtils';
import { RootState } from '../../../store/store';
import ExerciseCardForm from './ExerciseCardForm';
import { selectinput_types, selectInputTypesAsObject } from '../../../+store/definitions/inputs/reducers';
import { exerciseCardActions } from '../../../+store/exerciseCards/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectCardsBySelectedSession } from '../../../+store/exerciseCards/reducers';
import ClientExerciseCard from '../../../clientApp/program/components/ClientExerciseCard';
import { useParams } from 'react-router-dom';
import { StyledInput } from '../../../common/StyledInput';
import { sessionActions } from '../../../+store/sessions/actionTypes';
import { Delete, Save } from '@mui/icons-material';
import * as StyledAccordion from '../../../common/StyledAccordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UnfoldLess } from '@mui/icons-material';
import { saveSectionTemplate } from '../../services/sessionAPI';
import { closeDialogs, openNewSectionTemplate, openSelectSectionDialog, saved } from '../../../store/app/actions';

type Props = {
  session: SessionInterface;
  workout_id: number;
};

const SessionStyle = {
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  trigger: {
    position: 'fixed',
    bottom: '80px',
    right: '20px',
    zIndex: 1202,
  },
};

function SessionForm(props: Props) {
  const { programId } = useParams();
  const exercise_cards = useSelector((state: RootState) => selectCardsBySelectedSession(state));
  const user = useSelector((state: RootState) => state.auth.user);
  const [session, setSession] = React.useState<SessionInterface>(props.session);
  const dispatch = useDispatch();

  const inputTypes = useSelector((state: RootState) => selectInputTypesAsObject(state));

  const addCard = (sectionIndex: number) => {
    let newCard: ExerciseCardClass;
    if (exercise_cards.size > 0) {
      const card = Array.from(exercise_cards.values())[exercise_cards.size - 1];

      newCard = new ExerciseCardClass({
        ...card,
        session: card.session,
        workout: card.workout,
        config: { ...card.config },
      });
      delete newCard.id;
      delete newCard.client_inputs
    } else {
      newCard = new ExerciseCardClass({
        workout: props.workout_id,
        session: session.id,
        program: Number(programId),
      });
    }

    dispatch(exerciseCardActions.createExerciseCard(newCard, sectionIndex));
  };

  useEffect(() => {
    setSession(props.session);
  }, [props.session]);

  const intervalRename = useCallback(
    debounce(async (sectionIndex, name) => {
      await updateSectionName(sectionIndex, name);
    }, 2000),
    [],
  );

  const removeSection = (sectionIndex: number) => {
    dispatch(sessionActions.deleteSessionSection(session.id, sectionIndex));
  }

  const openSectionTemplate = (section: { name: string, ids: number[] }) => {
    dispatch(
      openNewSectionTemplate({ 
        section: {name: section.name, ids: section.ids}, 
        onConfirm: (section: {name: string, ids: number[]}) => saveSection(section), 
        onCancel: () => { dispatch(closeDialogs()) } 
      }));
  }

  const saveSection = async (section: { name: string, ids: number[] }) => {
    const response = await saveSectionTemplate(section.name, section.ids)
    if (response.ok) {
      const data = await response.json();
      dispatch(saved(data.message));
    }
  }

  const addSection = (sectionIndex: number) => {
    dispatch(openSelectSectionDialog({ sessionId: session.id, sectionIndex, onCancel: () => { dispatch(closeDialogs()) } }));
  }

  const updateSectionName = (sectionIndex: number, name: string) => {
    dispatch(sessionActions.renameSessionSection(session.id, sectionIndex, name));
  }

  const removeCard = (id: number, sectionIndex: number) => {
    return dispatch(exerciseCardActions.deleteExerciseCard(id, sectionIndex, session.id));
  };

  const cards = session.config.map((value, sectionIndex) => {
    const groupCards = value.ids.map((id: number, index) => {
      const card = exercise_cards.get(id)
      if (!card) return null;
      return user && (user?.account_type as AccountType) === AccountType.Client ?
        <ClientExerciseCard inputOptions={inputTypes} card={card} userId={user.id} key={card.id} index={index} />
        : (
          <ExerciseCardForm
            inputOptions={inputTypes}
            id={card.id!}
            remove={(id: number) => removeCard(id, sectionIndex)}
            card={card}
            key={card.id}
            index={index}
          />
        )
    });
    if (user && (user?.account_type as AccountType) === AccountType.Client) {
      if (groupCards.length === 0) return null;
      return <StyledAccordion.Accordion
        defaultExpanded={sectionIndex === 0}
        key={sectionIndex + "session"} className="cardsCont">
        <StyledAccordion.AccordionSummary
          mode='dark'
          expandIcon={<ExpandMoreIcon fontSize='large' />}
        >
          <div style={SessionStyle.sectionHeader}>
           
            <Typography fontSize='medium' variant='button' >{value.name}</Typography>
         
          </div>
        </StyledAccordion.AccordionSummary>
        <StyledAccordion.AccordionDetails>
          {groupCards}
        </StyledAccordion.AccordionDetails>
      </StyledAccordion.Accordion>
    }
    return (
      <>
        <Button
          color="info"
          fullWidth
          sx={{marginTop: '10px'}}
          key={sectionIndex + "button"}
          onClick={() => addSection(sectionIndex)}
        >
          <hr
            style={{
              height: .25,
              color: '#07383F',
              margin: '0 10px',
              backgroundColor: '#07383F',
              flexGrow: 1,
            }}
          />  + Exercise Group <hr
            style={{
              height: .25,
              color: '#07383F',
              margin: '0 10px',
              backgroundColor: '#07383F',
              flexGrow: 1,
            }}
          />
        </Button>
        <StyledAccordion.Accordion
          defaultExpanded={sectionIndex === 0}
          key={sectionIndex + "session"} className="cardsCont">
          <StyledAccordion.AccordionSummary
            mode='light'
            expandIcon={<ExpandMoreIcon fontSize="large" />}
          >
            <div style={SessionStyle.sectionHeader}>

              <StyledInput
                value={value.name}
                backgroundcolor='white'
                border=".25 solid #ccc"
                height="30px"
                fullWidth
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setSession({ ...session, config: session.config.map((v, i) => i === sectionIndex ? { ...v, name: e.target.value } : v) });
                  intervalRename(sectionIndex, e.target.value);
                }} />
              <Button
                title="Delete Exercise Group"
                sx={{padding: '0', minWidth: '45px'}}
                disabled={session.config.length < 2}
                onClick={(event) => {
                  event.stopPropagation();
                  removeSection(sectionIndex)}}>
                <Delete
                  color={session.config.length < 2 ? 'disabled' : 'error'} fontSize="large" />
              </Button>
              <Button
                title="Save Exercise Group" 
                sx={{padding: '0', minWidth: '45px'}}
                onClick={(event) => {
                  event.stopPropagation();
                  openSectionTemplate(value)
                  }}>
                
                <Save fontSize="large" />
              </Button>
            </div>
          </StyledAccordion.AccordionSummary>
          <StyledAccordion.AccordionDetails>
            {groupCards}
            <Button
              title="Add Exercise"
              variant="outlined"
              fullWidth
              onClick={() => addCard(sectionIndex)}
            >
              + Exercise
            </Button>
          </StyledAccordion.AccordionDetails>
        </StyledAccordion.Accordion>
      </>
    );
  })

  return (
    <div className="session">
      {inputTypes ?
        <>
          {cards}
          {
            user && (user?.account_type as AccountType) !== AccountType.Client && (
              <Button
              color="info"
              
              fullWidth
                onClick={() => addSection(session.config.length)}
              >
                <hr
                  style={{
                    height: .25,
                    color: '#07383F',
                    margin: '0 10px',
                    backgroundColor: '#07383F',
                    flexGrow: 1,
                  }}
                />  + Exercise Group <hr
                  style={{
                    height: .25,
                    color: '#07383F',
                    margin: '0 10px',
                    backgroundColor: '#07383F',
                    flexGrow: 1,
                  }}
                />
              </Button>
            )
          }

        </> : <div>Loading...</div>}
    </div>
  );
}
export default SessionForm;
