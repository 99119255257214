import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../ClientStyle/ClientCard.scss';
import {openPreviousInputs} from '../../../store/app/actions';
import { Button, CircularProgress, debounce, Typography } from '@mui/material';
import ClientCardInputs from './ClientCardInputs';
import { RootState } from '../../../store/store';
import { exerciseCardSelectors, selectClientInputs, selectPreviousClientInputs } from '../../../+store/exerciseCards/reducers';
import RestTimerDialog from '../dialogs/RestTimer';
import { useCardTotals } from '../../../common/hooks/useCardTotals';
import { Definition, ExerciseCardClass } from '../../../tsUtils';
import { useForm } from 'react-hook-form';
import { exerciseCardActions } from '../../../+store/exerciseCards/actionTypes';
import { ClientInputsInterface } from '../../../tsUtils/clientInputsTypes';
import { StyledInput } from '../../../common/StyledInput';

interface Props {
  index: number;
  card: ExerciseCardClass;
  userId: number;
  inputOptions: { [key: string]: Definition };
}

function ClientExerciseCard(props: Props) {

  const dispatch = useDispatch();
  const [adjustedMax, setAdjustedMax] = React.useState<any>(0);

  const client_inputs = useSelector((state: RootState) =>
    selectClientInputs(state, props.card.id!),
  );
  const [totals, updateTotals, totalsLoading] = useCardTotals();
  const previousInputs = useSelector((state: RootState) =>
    selectPreviousClientInputs(state, props.index),
  );

  function showPrevious() {
    dispatch(openPreviousInputs(props.userId, props.index));
  }

  useEffect(() => {
    if (client_inputs) {
      updateTotals(props.card, client_inputs.inputs);
    }
  }, [client_inputs]);

  const save = (adjusted: number) => {
    if (!client_inputs) return;
    dispatch(exerciseCardActions.updateClientInputsCard(props.card.id!, {
      ...client_inputs,
      adjust_max: {
        ...client_inputs.adjust_max,
        actual: adjusted,
      },
    }));
  };

  const intervalSave = useCallback(
    debounce(async (adjusted) => {
      await save(adjusted)
    }, 2000),
    [],
  );

  const onError = (error: any, e: any) => console.log(error);

  useEffect(() => {
    if (client_inputs && client_inputs.adjust_max) {
      setAdjustedMax(client_inputs.adjust_max.actual);
    }
  }, [client_inputs]);

  return props.inputOptions[1] ? (
    <div className="client-card">
      <div className="cardHead">
        <div className="head_item">
          <span style={{lineHeight:"42px"}} className="cardNo">{props.card.config.card_no}</span>
        </div>
        <div className="head_item">
            <Typography variant="button" sx={{ lineHeight: 1, fontSize: '.95em' }} component="span">
              {props.card.exercise?.name}
            </Typography>
        </div>
      </div>
      <div className="variation-cont">
        <div className="variation">
          <Typography variant="button"component="span">
            Variation 1:
          </Typography>
          <Typography variant="body1" >
            {props.card.config.variation_1 !== '' ? props.card.config.variation_1 : '-'}
          </Typography>
        </div>
        <div className="variation">
          <Typography variant="button" component="span">
            Variation 2:
          </Typography>
          <Typography variant="body1" >
            {props.card.config.variation_2 !== '' ? props.card.config.variation_2 : '-'}
          </Typography>
        </div>
        <div className="variation">
          <Typography variant="button" component="span">
            Equipment:
          </Typography>
          <Typography variant="body1" >
            {props.card.config.equipment !== '' ? props.card.config.equipment : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Adjusted Max:
          </Typography>
          <StyledInput fullWidth value={adjustedMax} onChange={(e) => {
            setAdjustedMax(e.target.value);
            intervalSave(e.target.value);
          }} />
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Tempo:
          </Typography>
          <Typography variant="body1" >
            {props.card.config.tempo !== '' ? props.card.config.tempo : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Rest:
          </Typography>
          {props.card.config.rest !== '' ? 
            <RestTimerDialog rest={props.card.config.rest} /> : 
            <Typography variant="body1" >
            -
            </Typography>
          }
        </div>
      </div>
      {
        previousInputs && (
      <div >
        <Button variant="outlined" color="primary" onClick={showPrevious} fullWidth size='small' >
          Previous Inputs
        </Button>
      </div>
        )
      }
      <div>
        {client_inputs ? (
          <ClientCardInputs
          cardIndex={props.index}
          inputs={client_inputs}
          prescribed={client_inputs.prescribed}
          inputOptions={props.inputOptions}
        />
        ) : (
          <div>No client inputs</div>
        )}
      </div>
      <div className="variation-cont">
        { 
        !totalsLoading ?
        Object.entries(totals).map(([key, value]: [string, string], index) => {
          if (value && !value.includes('NaN')) {
            return (
              <div key={props.card.id + key} className="variation">
                <Typography variant="button" component="span">
                  {key}:
                </Typography>
                <Typography variant="body1">
                  {value}
                </Typography>
              </div>
            );
            }
        }) :
        (
          <CircularProgress />
        )
        }
      </div>
    </div>
  ) : (
    <div className="card">not found</div>
  );
}

export default ClientExerciseCard;
