import React, { createRef, useEffect, useRef, useState } from 'react';
import FullCalendar, { EventContentArg, EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import '../../../style/Coach/Schedule.scss';
import { DateSelectArg } from '@fullcalendar/core';
import EventOverlay from '../components/EventOverlay';
import { EventBase } from '../../../tsUtils/eventTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import CreateEventModal from '../components/CreateEventModal';
import { eventSelectors } from '../../../+store/events/reducers';
import EditEventModal from '../components/EditEventModal';

interface CalendarProps {
  readonly: boolean;
}

function CalendarComponent({readonly}: CalendarProps) {
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const sideBar = useSelector((state: RootState) => state.app.sideBar);
  const [full, setFull] = useState(true);
  const [start, setStart] = useState<Date>();
  const [selectedEvent, setSelectedEvent] = useState<EventBase | null>(null);
  const [end, setEnd] = useState<Date>();
  const calendarRef = createRef<FullCalendar>();
  const [openEdit, setopen] = useState(false);
  const [title, setTitle] = useState<string | null>();
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  useEffect(() => {
    if (sideBar !== full) {
      setFull(sideBar);
      calendarRef.current?.getApi().updateSize();
    }
  }, [sideBar]);
  const events = useSelector((state: RootState) => eventSelectors.selectAll(state));
  function handleDateClick(arg: DateClickArg) {
    setSelectedDate(arg.date);
    setOpenCreate(true);
    
  }
  function handleDateSelect(arg: DateSelectArg) {
    setStart(arg.start);
    setEnd(arg.end);
    setOpenCreate(true);
  }
  function handleEventSelect(event: EventBase) {
    setSelectedEvent(event);
    setopen(true);
  }
  function handleCloseEdit() {
    setopen(false);
  }
  useEffect(() => {
    if(calendarRef.current){
      calendarRef.current.getApi().refetchEvents();
    }
  } , [events])

  return (
    <div style={{marginTop: readonly ? '10px' : '0px'}} className="calendar-cont">
      <div className="calendar">
        <FullCalendar
          themeSystem="bootstrap"
          plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin, timeGridPlugin]}
          selectable={true}
          ref={calendarRef}
          height="100%"
          select={arg => {
            if (readonly) return;
            handleDateSelect(arg);
          }}
          dateClick={(arg: DateClickArg) => {
            if (readonly) return;
            handleDateClick(arg)
          }}
          initialView={isMobile ? 'timeGridDay' : 'timeGridFiveDay'}
          headerToolbar={{
            start: 'dayGridMonth,timeGridFiveDay,timeGridDay',
            end: 'prev,today,next',
          }}
          views={{
            timeGridDay: {
              type: 'timeGrid',
              buttonText: 'D',
            },
            timeGridFiveDay: {
              type: 'timeGrid',
              duration: { days: 7 },
              buttonText: 'W',
            },
            dayGridMonth: {
              type: 'dayGridMonth',
              buttonText: 'M',
            },
          }}
          events={events?.map((e: EventBase) => {
            return { ...e, id: e.id?.toString() };
          })}
          eventClick={(arg: any) => {
            arg.jsEvent.preventDefault(); 
            const ev = events?.find(e => e.id === Number(arg.event.id));
            handleEventSelect(ev!);
          }}
          eventContent={(arg: EventContentArg) => {
            const ev = events?.find(e => e.id === Number(arg.event.id));
            if (ev) {
              return (
                <EventOverlay event={ev} />
              );
            }
          }}
        ></FullCalendar>
      </div>
      <CreateEventModal
        open={openCreate}
        start={start}
        end={end}
        date={selectedDate}
        onClose={() => setOpenCreate(false)}
      />
      <EditEventModal readonly={readonly} event={selectedEvent} open={openEdit} onClose={handleCloseEdit} />
    </div>
  );
}

export default CalendarComponent;
