import React, { useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { SessionQuestion, WorkoutInterface, WorkoutType } from '../../../tsUtils';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { workoutActions } from '../../../+store/workouts/actionTypes';
import { RootState } from '../../../store/store';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DeleteOutlined } from '@mui/icons-material';
import { useConfigDateTime } from '../hooks/UseConfigDate';

interface Props {
  title: string;
  programid: number;
  startDate: Date;
  config: {
    day: Day;
    week: number;
  };
  open: boolean;
  onCancel: () => void;
  workout?: WorkoutInterface;
  questions?: SessionQuestion[];
}
export interface WorkoutPromptQuestions {
  instructions: string;
}

interface WorkoutForm {
  name: string;
  workout_type: WorkoutType;
  time?: Date;
  questions: SessionQuestion[];
}

const transitionStyle1 = (aiForm: boolean) => {
  if (aiForm) {
    return {
      transition: 'all 0.5s ease-in-out',
      transform: 'translateX(-50%)',
    };
  } else {
    return {
      transition: 'all 0.5s ease-in-out',
      transform: 'translateX(0)',
    };
  }
};

function CreateWorkoutDialog(props: Props) {
  const loading = useSelector((state: RootState) => state.workouts.loading);
  const loaded = useSelector((state: RootState) => state.workouts.loaded);
  const user = useSelector((state: RootState) => state.auth.user);
  const [aiForm, setAiForm] = React.useState(false);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const {date_time, setDateTime, setInitialDateTime} = useConfigDateTime(new Date());
  const [workoutPromptQuestions, setworkoutPromptQuestions] = React.useState<WorkoutPromptQuestions>({
    instructions: '',
  });
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState,
  } = useForm<WorkoutForm>({
    defaultValues: props.workout
      ? {
        name: props.workout.name,
        workout_type: props.workout.workout_type,
        questions: props.questions || [],
      }
      : {
        name: '',
        workout_type: WorkoutType.ResistanceTraining,
      },
  });
  const { fields, prepend, append, remove, swap } = useFieldArray<WorkoutForm>({
    control,
    name: 'questions',
  });
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null)

  const submit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
    }
  }

  const onSubmit: SubmitHandler<WorkoutForm> = data => {
    if (props.workout) {
      dispatch(
        workoutActions.updateWorkoutDetails(
          {
            ...props.workout,
            name: data.name,
          },
          data.questions,
        ),
      )
    } else if (aiForm === false) {
      dispatch(
        workoutActions.createWorkout(
          {
            name: data.name,
            workout_type: data.workout_type,
            program: props.programid,
          },
          data.questions,
          {
            ...props.config,
            date_time: date_time.toISOString(),
          },
        ),
      )
    } else {
      dispatch(
        workoutActions.generateWorkout(
          {
            name: data.name,
            workout_type: data.workout_type,
            program: props.programid,
          },
          data.questions,
          {
            ...props.config,
            date_time: date_time.toISOString(),
          },
          workoutPromptQuestions
        )
      );
    }
  };

  function done() {
    reset({
      name: '',
      workout_type: WorkoutType.ResistanceTraining,
    });
    setAiForm(false)
    setworkoutPromptQuestions({ ...workoutPromptQuestions, instructions: '' })
    props.onCancel();
  }

  useEffect(() => {
    if (loaded && formState.isSubmitSuccessful) {
      done();
    }
  }, [loaded, formState]);
  useEffect(() => {
    if (props.open) {
      setInitialDateTime(props.startDate, props.config.day, props.config.week, new Date());
    }
  }, [props.open]);
  function onCancel() {
    props.onCancel();
  }
  function transitionForm() {
    setAiForm(!aiForm);
  }

  return (
    <Dialog fullScreen={isMobile} open={props.open}>
      <DialogTitle>{loading ? 'Creating Workout' : props.title}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <div className='program-form-cont'>
          {loading ? (
            <div style={{width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <CircularProgress />
            </div>
          ) : (
              <div className='form-parent' style={transitionStyle1(aiForm)}>
                <form className='workout-form' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      gap: 5,
                      height: 200,
                    }}
                  >
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant="filled"
                          autoComplete='off'
                          title="Workout Name"
                          label="Workout Name"
                        />
                      )}
                    />
                    {!props.workout && (
                    <Controller
                      control={control}
                      name="workout_type"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel variant="filled">Workout Type</InputLabel>
                          <Select
                            variant="filled"
                            fullWidth
                            title="Workout Type"
                            {...field}
                            onChange={e => setValue('workout_type', e.target.value as WorkoutType)}
                            error={Boolean(formState.errors?.workout_type)}
                          >
                            <MenuItem
                              key={WorkoutType.ResistanceTraining}
                              value={WorkoutType.ResistanceTraining}
                            >
                              Resistance Training
                            </MenuItem>
                            <MenuItem key={WorkoutType.Conditioning} value={WorkoutType.Conditioning}>
                              Conditioning
                            </MenuItem>
                            <MenuItem key={WorkoutType.Other} value={WorkoutType.Other}>
                              Other
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    /> )}
            
                      <TimePicker
                        value={date_time}
                        onChange={(newValue) => {
                          setDateTime(newValue as Date);
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="filled"
                            autoComplete='off'
                            title="Workout Time"
                            label="Workout Time"
                            error={formState.errors.time}
                            helperText="Input Required"
                          />
                        )}
                      />
               
                  </div>
                  <div className="workout-questions">
                    {fields.map((question, index) => (
                      <div
                        key={index}
                      style={{ display: 'flex', width: '100%', gap: 5, alignItems: 'center' }}
                    >
                      <Controller
                        control={control}
                        name={`questions.${index}.type`}
                        render={({ field }) => (
                          <Select
                            {...field}
                            defaultValue="string"
                            variant="filled"
                            sx={{ width: 150 }}
                            title="Question Type"
                            label="Type"
                          >
                            <MenuItem value="string">Text</MenuItem>
                            <MenuItem value="number">Number</MenuItem>
                            <MenuItem value="scale">Scale</MenuItem>
                            <MenuItem value="boolean">Tru/Fal</MenuItem>
                          </Select>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`questions.${index}.question`}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            autoComplete='off'
                            multiline
                            variant="filled"
                            title={`Question ${index + 1}`}
                            label={`Question ${index + 1}`}
                          />
                        )}
                      />
                      <DeleteOutlined color="error" fontSize="small" onClick={() => remove(index)} />
                    </div>
                  ))}
                    <Button style={{ width: '100%' }} onClick={() => append(new SessionQuestion())}>Add Question</Button>
                  </div>
                  <div className="form-buttons">
                    <Button onClick={() => onCancel()} variant="outlined" color="info">
                      Cancel
                    </Button>
                    {/* generate workout button */}
                    { !props.workout && user?.email === 'nick@clevercoachtech.com' && (
                      <Button variant="outlined" onClick={() => transitionForm()} color="info">
                        Generate
                      </Button>
                    )
                    }
                    <Button type="submit" variant="contained" color="primary">
                      { props.workout ? 'Save' : 'Create' }
                    </Button>
                  </div>
                </form>
                <div className="workout-form" id="ai-info-form">
                  <div >
                    <TextField
                      type="text"
                      variant="filled"
                      minRows={6}
                      label={<Tooltip
                        title={<React.Fragment>
                          <Typography variant="button" fontSize={10} color="inherit">Pro Tip</Typography>
                          <p>
                            For best results, include more details.
                          </p>
                        </React.Fragment>}
                      >
                        <span>Generation Prompt
                          <InfoOutlinedIcon fontSize='small' sx={{ marginLeft: '6px' }} /></span>
                      </Tooltip>}
                      placeholder='Build a workout...'
                      fullWidth
                      multiline
                      value={workoutPromptQuestions.instructions}
                      onChange={event =>
                        setworkoutPromptQuestions({ ...workoutPromptQuestions, instructions: event.target.value })
                      }
                    />
                  </div>
                  <div></div>
                  <div className="form-buttons">
                    <Button onClick={() => transitionForm()} variant="outlined" color="primary">
                      Workout Info
                    </Button>
                    <Button
                      form="program-info-form"
                      onClick={submit}
                      variant="contained"
                      color="primary"
                    >
                      Generate Workout
                    </Button>
                  </div>
                </div>
              </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CreateWorkoutDialog;
