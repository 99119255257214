import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { UserBest } from '../../tsUtils';
import { closeDialogs, openAssessmentResultCreate, openConfirmDialog, openSlopeGraphDialog } from '../../store/app/actions';
import { OpenInNew } from '@mui/icons-material';
import { AssessmentResult, AssessmentResultSummaryClient } from '../../tsUtils/assessmentTypes';
import { assessmentActions } from '../../+store/assessments/actionTypes';
import { Typography } from '@mui/material';

export interface ClientProps {

}

const ClientStyle = {
  container: {
    padding: '.5rem',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gap: '.5rem',
  },
  details: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  detailsItem: {
    minWidth: '80px',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left',
  },
  tableRow: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  tableCell: {
    padding: '10px',
    minWidth: '150px',
    alignContent: 'start',
  },
  clickableRow: {
    cursor: 'pointer',
  },
};

export default function Client(props: ClientProps) {
  const client = useSelector((state: RootState) => state.clients.selected_client);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const dispatch = useDispatch();

  const saveResult = (formData: AssessmentResult) => {
    return dispatch(assessmentActions.createAssessmentResult(formData));
  };

  const updateResult = (result: AssessmentResult) => {
    return dispatch(assessmentActions.updateAssessmentResult(result));
  };

  const openResultCreate = (assessmentResult: AssessmentResultSummaryClient) => {
    dispatch(
      openAssessmentResultCreate({
        assessmentResult: new AssessmentResult({
          ...assessmentResult,
          assessment: assessmentResult.assessment.id,
          client: client?.id,
        }),
        saveResult,
        assessmentName: assessmentResult.assessment.name,
        updateResult,
        cancel: () => dispatch(closeDialogs()),
      })
    );
  };

  const openSlopeCurve = (bests: UserBest) => {
    dispatch(
      openSlopeGraphDialog({
        exercise: bests.exercise.name,
        coef: bests.bests.velocity.coefficients,
        intercept: bests.bests.velocity.intercept,
        isMobile,
        onClose: () => dispatch(closeDialogs()),
      })
    );
  };

  return (
    <div style={ClientStyle.container}>
      <div style={ClientStyle.main}>
      <div className="card">
        <Typography variant='button' fontSize='x-large'>{client?.first_name} {client?.last_name}</Typography>
        <div style={ClientStyle.details as React.CSSProperties}>
        <div style={ClientStyle.detailsItem as React.CSSProperties}>
          <Typography variant='button'><strong>Email:</strong></Typography>
          <Typography variant='button'>{client?.email}</Typography>
        </div>
        <div style={ClientStyle.detailsItem as React.CSSProperties}>
          <Typography variant='button'><strong>DOB:</strong></Typography>
          <Typography variant='button'>{client?.metrics.dob ?? '-'}</Typography>
        </div>
        <div style={ClientStyle.detailsItem as React.CSSProperties}>
          <Typography variant='button'><strong>Height:</strong></Typography>
          <Typography variant='button'>{client?.metrics.height ?? '-'}</Typography>
        </div>
        <div style={ClientStyle.detailsItem as React.CSSProperties}>
          <Typography variant='button'><strong>Weight:</strong></Typography>
          <Typography variant='button'>{client?.metrics.weight ?? '-'}</Typography>
        </div>
        </div>
      </div>
      <div style={{overflowX: 'auto'}} className="card">
      <Typography variant='button' fontSize='x-large'>Assessment Results</Typography>
        <table style={ClientStyle.table as React.CSSProperties}>
        <thead style={ClientStyle.tableHeader as React.CSSProperties}>
          <tr>
          <th style={ClientStyle.tableCell}><Typography variant='button'>Assessment Name</Typography></th>
          <th style={ClientStyle.tableCell}><Typography variant='button'>Assessment Date</Typography></th>
          <th style={ClientStyle.tableCell}><Typography variant='button'>Assessments</Typography></th>
          <th style={ClientStyle.tableCell}><Typography variant='button'>Type</Typography></th>
          </tr>
        </thead>
        <tbody>
          {client?.assessment_results.map((assessment, index) => (
          <tr
            key={index}
            style={{ ...ClientStyle.tableRow, ...ClientStyle.clickableRow }}
            onClick={() => openResultCreate(assessment)}
          >
            <td style={ClientStyle.tableCell}><Typography variant='button'>{assessment.assessment.name}</Typography></td>
            <td style={ClientStyle.tableCell}>
            <Typography variant='button'>{new Date(assessment.assessment_date).toLocaleDateString()}</Typography>
            </td>
            <td style={ClientStyle.tableCell}>
            {assessment.results.map((result, index) => (
              <Typography variant='button' key={index}>
              {result.exercise?.name}
              </Typography>
            ))}
            </td>
            <td style={ClientStyle.tableCell}>
            {assessment.results.map((result, index) => (
              <Typography variant='button' key={index}>
              {result.assessment_type}
              </Typography>
            ))}
            </td>
          </tr>
          ))}
        </tbody>
        </table>
      </div>
      <div style={{overflowX: 'auto'}} className="card">
      <Typography variant='button' fontSize='x-large'>Bests</Typography>
        <table style={ClientStyle.table as React.CSSProperties}>
        <thead style={ClientStyle.tableHeader as React.CSSProperties}>
          <tr>
          <th style={ClientStyle.tableCell}><Typography variant='button'>Exercise</Typography></th>
          <th style={ClientStyle.tableCell}><Typography variant='button'>1RM</Typography></th>
          <th style={ClientStyle.tableCell}><Typography variant='button'>MVT</Typography></th>
          <th style={ClientStyle.tableCell}><Typography variant='button'>Velocity Curve</Typography></th>
          </tr>
        </thead>
        <tbody>
          {client?.bests.map((best, index) => (
          <tr key={index} style={ClientStyle.tableRow}>
            <td style={ClientStyle.tableCell}><Typography variant='button'>{best.exercise.name}</Typography></td>
            <td style={ClientStyle.tableCell}><Typography variant='button'>{best.bests.one_rm}</Typography></td>
            <td style={ClientStyle.tableCell}>
            <Typography variant='button'>{best.bests.velocity.mvt ? best.bests.velocity.mvt : '-'}</Typography>
            </td>
            <td
            style={{
              ...ClientStyle.tableCell,
              cursor: best.bests.velocity?.intercept ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (best.bests.velocity?.coefficients) {
              openSlopeCurve(best);
              }
            }}
            >
            {best.bests.velocity?.coefficients ? (
              <Typography variant='button'>
              View curve <OpenInNew color="primary" />
              </Typography>
            ) : (
              <Typography variant='button'>Curve not calculated</Typography>
            )}
            </td>
          </tr>
          ))}
        </tbody>
        </table>
      </div>
      </div>
    </div>
  );
}

