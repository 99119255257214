import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../style/Coach/Clients.scss';
import '../../style/Coach/ListPage.scss';
import { clientSelectors } from '../../+store/clients/reducers';
import { RootState } from '../../store/store';
import BaseDialog from '../programs/dialogs/BaseDialog';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import UserAvatar from '../../common/UserAvatar';
import { ListUserInterface, User } from '../../tsUtils';
import { deleteClient, inviteClient } from '../../+store/clients/actionTypes';
import PopupMenu from '../../common/PopupMenu';
import { Add, DeleteOutlined, EditOutlined,MailOutline, MoreVert } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
const CoachStyleTable = {
  tableRow: {
    gridTemplateColumns: '10% 30% 30% 25% 5%'
  },
  tableRowMobile: {
    gridTemplateColumns: '10% 40% 40% 10%'
  }
}

function Coaches() {
  const coaches = useSelector((state: RootState) => state.organisations.coaches || []);
  const loading = useSelector((state: RootState) => state.organisations.loading);
  const [deletingCoach, setDeletingCoach] = useState<ListUserInterface | null>(null);
  const [editingCoach, setEditingCoach] = useState<User | null>(null);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const dispatch = useDispatch();
  useEffect(() => {}, [coaches]);
  const navigate = useNavigate();

  const deleteItem = (data: ListUserInterface) => setDeletingCoach(data);

  const invite = (data: ListUserInterface) => dispatch(inviteClient(data.id));

  const editItem = (data: any) => setEditingCoach(data);

  const confirmDelete = (id: number) => {
    dispatch(deleteClient(id));
    setDeletingCoach(null);
  };

  const viewCoach = (coach: ListUserInterface) => {
    navigate(`/coach/coaches/${coach.id}`);
  }

  return (
    <div className="listPage">
      <div className="listgroup">
        <div className="listActions">
          
        </div>
        <div className="listDiv">
          <Box color="primary" sx={isMobile ? CoachStyleTable.tableRowMobile : CoachStyleTable.tableRow} className="list-header-row">
            <Typography
              component="div"
              className="list-row-item item1"
              style={{ lineHeight: 1.1 }}
              variant="button"
            ></Typography>
            <Typography
              component="div"
              className="list-row-item item2"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Name
            </Typography>

            <Typography
              component="div"
              className="list-row-item item3"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Email
            </Typography>
            {!isMobile && (
            <Typography
              component="div"
              className="list-row-item item4"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Is Active
            </Typography>
            )}
            <Typography
              component="div"
              className={`list-row-item ${isMobile ? "item4" : "item5"}`}
              style={{ lineHeight: 1.1 }}
              variant="button"
            ></Typography>
          </Box>
          {loading ? (
            <div className="loading-cont">
              <CircularProgress />
            </div>
          ) : (
            <div className="list-body">
              {coaches.length > 0 ? (
                coaches.map(coach => (
                  <div key={coach.id} style={isMobile ? CoachStyleTable.tableRowMobile : CoachStyleTable.tableRow} className="list-row" onClick={() => viewCoach(coach)}>
                    <div className="user-pill-cont item1">
                      <UserAvatar first={coach.first_name} last={coach.last_name} size={36} />
                    </div>
                    <Typography variant="body2" className="list-row-item item2" component="div">
                      {coach.first_name
                        ? `${coach.first_name} ${coach.last_name}`
                        : `${coach.username}`}
                    </Typography>

                    <Typography
                      variant="body2"
                      title={coach.email}
                      className="list-row-item item3"
                      style={{ overflow: 'hidden', wordBreak: 'break-word' }}
                      component="div"
                    >
                      {coach.email}
                    </Typography>
                    {!isMobile && (
                    <div className="list-row-item item4">
                      {coach.is_active ? 'Yes' : 'No'}
                    </div>
                    )}
                    <div className={`list-row-item ${isMobile ? "item4" : "item5"}`}>
                        <PopupMenu 
                          menuItems={[
                            {text: 'Edit', icon: <EditOutlined />, action: () => editItem(coach)},
                            {text: 'Delete', icon: <DeleteOutlined />, action: () => deleteItem(coach)},
                            {text: 'Invite', icon: <MailOutline />, action: () => invite(coach), disabled: coach.is_active}
                          ]} 
                          icon={<MoreVert />} 
                          popoverId={`coach-list-${coach.id}`} 
                        
                        />
                      </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    gridRow: '1/-1',
                    gridColumn: '1/-1',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    color="InactiveCaptionText"
                    sx={{ width: '100%' }}
                    component="span"
                    variant="h4"
                  >
                    No Coaches Found
                  </Typography>
                </div>
              )}
            </div>
          )}
          {deletingCoach != null && (
            <div></div>
            // <ConfirmDeleteCoach
            //   coach={deletingCoach}
            //   onConfirm={confirmDelete}
            //   onCancel={() => setDeletingCoach(null)}
            //   open={deletingCoach !== null}
            // />
          )}
          {editingCoach != null && (
            <Dialog open={editingCoach != null}>
              <DialogTitle>Edit Coach</DialogTitle>
              <DialogContent>
              {/* <CreateCoachForm onClose={() => setEditingCoach(null)} coach={editingCoach} /> */}
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
}

export default Coaches;
