import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { StyledSmallInput } from '../../common/StyledInput';
type Props = {
  onChange: (value: string) => void;
  value?: string;
  format: 'mm:ss';
  className?: string;
  size: 'small' | 'medium';
};
const groupStyle = {
  display: 'flex',
  width: 'calc(100% - 8px)',
  justifyContent: 'center',
  alignItems: 'center',
  height: '32px',
  background: '#eee',
  borderRadius: '4px',
};

function TimeInput(props: Props) {
  const [input1, setinput1] = useState(props.value?.split(':')[0] ?? '00');
  const [input2, setinput2] = useState(props.value?.split(':')[1] ?? '00');
  const [input3, setinput3] = useState(props.value?.split(':')[2] ?? '00');

  function emitChange(one: string, two: string, three: string) {
    const value = [one, two, three].join(':');
    props.onChange(value)
  }

  return (
      <div style={groupStyle}>
        <StyledSmallInput
          placeholder="MM"
          value={input2}
          variant="outlined"
          size="small"
          label="Rest"
          sx={{ width: '50%', minWidth: '72.5px' }}
          onChange={event => {
            setinput2(event.target.value);
            emitChange(input1, event.target.value, input3);
          }}
        />
        :
        <StyledSmallInput
          placeholder="SS"
          value={input3}
          variant="outlined"
          size="small"
          sx={{ width: '50%', minWidth: '72.5px' }}
          onChange={event => {
            setinput3(event.target.value);
            emitChange(input1, input2, event.target.value);
          }}
        />
      </div>
  );
}

export default TimeInput;
