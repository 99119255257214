import { fork, all } from "redux-saga/effects";
import { authSaga } from "./auth/sagas";
import { programSaga } from '../+store/programs/sagas';
import { definitionSaga } from '../+store/definitions/inputs/sagas';
import { clientSaga } from '../+store/clients/sagas';
import {accountDetailsSaga} from '../+store/account/sagas';
import { workoutSaga } from '../+store/workouts/sagas';
import { sessionSaga } from '../+store/sessions/sagas';
import { exerciseSaga } from '../+store/definitions/exercises/sagas';
import { exerciseCardSaga } from '../+store/exerciseCards/sagas';
import { eventSaga } from '../+store/events/sagas';
import { templateSaga } from '../+store/templates/sagas';
import { assessmentSaga } from "../+store/assessments/sagas";
import { appSaga } from "./app/sagas";
import { organisationSaga } from "../+store/organisations/sagas";

export default function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(authSaga),
    fork(programSaga),
    fork(workoutSaga),
    fork(sessionSaga),
    fork(clientSaga),
    fork(definitionSaga),
    fork(exerciseSaga),
    fork(exerciseCardSaga),
    fork(eventSaga),
    fork(templateSaga),
    fork(accountDetailsSaga),
    fork(assessmentSaga),
    fork(organisationSaga),
  ]);
}
