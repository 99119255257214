import { actionTypes } from "./actionTypes";
import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { organisationActions } from "./actionTypes";
import { addOrganisation, addOrganisations, deleteOrganisation, setError, updateOrganisation, setSelectedOrganisation, getCoachesSuccess } from "./reducers";
import * as organisationApi from '../../coachApp/services/organisationAPI'
import { SuccessResponse } from "../../tsUtils/responseType";
import { ListUserInterface, Organisation, OrganisationDetails } from "../../tsUtils";


function* deleteSingleOrganisation(action: any) {
  try {
    const response: SagaReturnType<typeof organisationApi.deleteOrganisation> = yield call(organisationApi.deleteOrganisation, action.organisation);
    if (response.ok) {
      const data: SuccessResponse<string > = yield response.json();
      yield put(deleteOrganisation(action.organisation));
    }
  } catch (error) {
    yield put(setError(error));
  }
}

function* getOrganisations(action: any) {
  try {
    const response: SagaReturnType<typeof organisationApi.listOrganisations> = yield call(organisationApi.listOrganisations);
    if (response.ok) {
      const data: SuccessResponse<Organisation[]> = yield response.json();
      yield put(addOrganisations(data.data));
    }
  } catch (error) {
    yield put(setError(error));
  }
}

function* createOrganisation(action: any) {
  try {
    const response: SagaReturnType<typeof organisationApi.createOrganisation> = yield call(organisationApi.createOrganisation, action.organisation);
    if (response.ok) {
      const data: SuccessResponse<Organisation> = yield response.json();
      yield put(addOrganisation(data.data));
    }
  } catch (error) {
    yield put(setError(error));
  }
}

function* updateSingleOrganisation(action: any) {
  try {
    const response: SagaReturnType<typeof organisationApi.updateOrganisation> = yield call(organisationApi.updateOrganisation, action.organisation.id, action.organisation);
    if (response.ok) {
      const data: SuccessResponse<Organisation> = yield response.json();
      yield put(updateOrganisation(data.data));
    }
  } catch (error) {
    yield put(setError(error));
  }
}

function* setSelectedOrg(action: any) {
  try {
    const response: SagaReturnType<typeof organisationApi.retrieveOrganisation> = yield call(organisationApi.retrieveOrganisation, action.id);
    if (response.ok) {
      const data: SuccessResponse<OrganisationDetails> = yield response.json();
      yield put(setSelectedOrganisation(data.data));
    }
  } catch (error) {
    yield put(setError(error));
  }
}

function* getOrgCoaches(action: any) {
  try {
    const response: SagaReturnType<typeof organisationApi.getCoaches> = yield call(organisationApi.getCoaches, action.organisation);
    if (response.ok) {
      const data: SuccessResponse<ListUserInterface[]> = yield response.json();
      yield put(getCoachesSuccess(data.data));
    }

  } catch {

  }
}

// function* getOrganisationUsers(action: any) {
//   try {
//     const response = yield call(getOrganisationUsersApi, action.organisation);
//     yield put(organisationActions.getOrganisationUsersSuccess(response.data));
//   } catch (error) {
//     yield put(setError(error));
//   }
// }

export function* organisationSaga() {
  yield all([
    takeLatest(actionTypes.GET_ORGANISATIONS, getOrganisations),
    takeLatest(actionTypes.CREATE_ORGANISATION, createOrganisation),
    takeLatest(actionTypes.UPDATE_ORGANISATION, updateSingleOrganisation),
    takeLatest(actionTypes.DELETE_ORGANISATION, deleteSingleOrganisation),
    takeLatest(actionTypes.SET_SELECTED_ORGANISATION, setSelectedOrg),
    takeLatest(actionTypes.GET_COACHES, getOrgCoaches),
    // takeLatest(actionTypes.GET_ORGANISATION_USERS, getOrganisationUsers),
  ]);
}