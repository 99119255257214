import { Props } from "html-react-parser/lib/attributes-to-props";
import React from "react";

export const CLOSE_DIALOGS = "[app] Close Dialogs";
export const CLOSE_DIALOGS_SUCCESS = "[app] Close Dialogs Success";
export const OPEN_REPEAT_WORKOUT_DIALOG = "[app] Open Repeat Workout Dialog";
export const OPEN_CREATE_WORKOUT_DIALOG = "[app] Open Create Workout Dialog";
export const OPEN_CREATE_PRODUCT_DIALOG = "[app] Open Create Product Dialog";
export const OPEN_CREATE_ASSESSMENT = "[app] Open Create Assessment";
export const OPEN_CREATE_ASSESSMENT_RESULT = "[app] Open Create Assessment Result";
export const OPEN_CREATE_WARMUP_DIALOG = "[app] Open Create Warmup Dialog";
export const OPEN_CREATE_COOLDOWN_DIALOG = "[app] Open Create Cooldown Dialog";
export const OPEN_WARMUP_COOLDOWN_DETAILS_DIALOG = "[app] Open Warm Up Details Dialog";
export const OPEN_CONFIRM_DIALOG = "[app] Open Confirm Dialog";
export const OPEN_SELECT_SECTION_DIALOG = "[app] Open Select Section Dialog";
export const SET_DIALOG_CHILDREN_SUCCESS = "[app] Set Dialog Children Success";
export const TOGGLE_SIDEBAR = '[app] Toggle Sidebar';
export const SET_MOBILE = '[app] Set Mobile';
export const CLOSE_PREVIEW_INPUTS = '[app] Close Preview Inputs';
export const OPEN_PREVIOUS_INPUTS = '[app] Open Previous Inputs';
export const SAVE = '[app] Save';
export const SAVE_SUCCESS = '[app] Save Success';
export const ERROR = '[app] Error';
export const REMOVE_ERROR = '[app] Remove Error';
export const OPEN_ADD_WARMUP_DIALOG = '[app] Open Add Warmup Dialog';
export const OPEN_ADD_COOLDOWN_DIALOG = '[app] Open Add Cooldown Dialog';
export const OPEN_SLOPE_GRAPH_DIALOG = '[app] Open Slope Graph Dialog';
export const OPEN_NEW_SECTION_TEMPLATE = '[app] Open New Section Template';
export const OPEN_ADD_CLIENTS_TO_PROGRAM_DIALOG = '[app] Open Add Clients To Program Dialog';
export const TOGGLE_CALCULATOR = '[app] Toggle Calculator';

export function closeDialogs() {
  return {
    type: CLOSE_DIALOGS,
  };
}
export function closeDialogsSuccess() {
  return {
    type: CLOSE_DIALOGS_SUCCESS,
  };
}
export function openRepeatWorkoutDialog(props: any) {
  return {
    type: OPEN_REPEAT_WORKOUT_DIALOG,
    payload: props,
  };
}
export function openCreateWorkoutDialog(props: any) {
  return {
    type: OPEN_CREATE_WORKOUT_DIALOG,
    payload: props,
  };
}
export function openCreateProductDialog(props: any) {
  return {
    type: OPEN_CREATE_PRODUCT_DIALOG,
    payload: props,
  };
}
export function openCreateAssessment(props: any) {
  return {
    type: OPEN_CREATE_ASSESSMENT,
    payload: props,
  };
}
export function openConfirmDialog(props: any) {
  return {
    type: OPEN_CONFIRM_DIALOG,
    payload: props,
  };
}
export function openCreateWarmupDialog(props: any) {
  return {
    type: OPEN_CREATE_WARMUP_DIALOG,
    payload: props,
  };
}
export function openCreateCooldownDialog(props: any) {
  return {
    type: OPEN_CREATE_WARMUP_DIALOG,
    payload: props,
  };
}
export function openWarmupCooldownDetailsDialog(props: any) {
  return {
    type: OPEN_WARMUP_COOLDOWN_DETAILS_DIALOG,
    payload: props,
  };
}
export function openAddWarmupDialog(props: any) {
  return {
    type: OPEN_ADD_WARMUP_DIALOG,
    payload: props,
  };
}
export function openAddClientsToProgramDialog(props: any) {
  return {
  type: OPEN_ADD_CLIENTS_TO_PROGRAM_DIALOG,
    payload: props,
  };
}
export function openAddCooldownDialog(props: any) {
  return {
    type: OPEN_ADD_COOLDOWN_DIALOG,
    payload: props,
  };
}
export function openAssessmentResultCreate(props: any) {
  return {
    type: OPEN_CREATE_ASSESSMENT_RESULT,
    payload: props,
  };
}
export function openSelectSectionDialog(props: any) {
  return {
    type: OPEN_SELECT_SECTION_DIALOG,
    payload: props,
  };
}
export function openNewSectionTemplate(props: any) {
  return {
    type: OPEN_NEW_SECTION_TEMPLATE,
    payload: props,
  };
}
export function setDialogChildrenSuccess(children: { element: React.LazyExoticComponent<(props: Props) => JSX.Element>; props: any }) {
  return {
    type: SET_DIALOG_CHILDREN_SUCCESS,
    payload: children,
  };
}
export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}
export function setMobile(isMobile: boolean) {
  return {
    type: SET_MOBILE,
    payload: isMobile,
  };
}
export function openPreviousInputs(userId: number, index: number) {
  return {
    type: OPEN_PREVIOUS_INPUTS,
    userId,
    index,
  }
}
export function closePreviousInputs() {
  return {
    type: CLOSE_PREVIEW_INPUTS,
  };
}
export function saved(message: string = 'Saved') {
  return {
    type: SAVE,
    message,
  };
}
export function savedSuccess() {
  return {
    type: SAVE_SUCCESS,
  };
}
export function error(message: string = 'Error') {
  return {
    type: ERROR,
    message
  };
}
export function removeError() {
  return {
    type: REMOVE_ERROR,
  };
}
export function openSlopeGraphDialog(props: any) {
  return {
    type: OPEN_SLOPE_GRAPH_DIALOG,
    payload: props,
  };
}
export function toggleCalculator() {
  return {
    type: TOGGLE_CALCULATOR,
  };
}
export interface ClosePrograms {
  type: typeof CLOSE_DIALOGS;
}
export interface CloseProgramsSuccess {
  type: typeof CLOSE_DIALOGS_SUCCESS;
}
export interface OpenRepeatWorkoutDialog {
  type: typeof OPEN_REPEAT_WORKOUT_DIALOG;
  payload: any;
}
export interface OpenCreateWorkoutDialog {
  type: typeof OPEN_CREATE_WORKOUT_DIALOG;
  payload: any;
}
export interface OpenCreateProductDialog {
  type: typeof OPEN_CREATE_PRODUCT_DIALOG;
  payload: any;
}
export interface OpenCreateAssessment {
  type: typeof OPEN_CREATE_ASSESSMENT;
  payload: any;
}
export interface OpenCreateAssessmentResult {
  type: typeof OPEN_CREATE_ASSESSMENT_RESULT;
  payload: any;
}
export interface OpenConfirmDialog {
  type: typeof OPEN_CONFIRM_DIALOG;
  payload: any;
}
export interface OpenNewSectionTemplate {
  type: typeof OPEN_NEW_SECTION_TEMPLATE;
  payload: any;
}
export interface SetDialogChildrenSuccess {
  type: typeof SET_DIALOG_CHILDREN_SUCCESS;
  payload: { element: React.LazyExoticComponent<(props: Props) => JSX.Element>; props: any };
}
export interface OpenSelectSectionDialog {
  type: typeof OPEN_SELECT_SECTION_DIALOG;
  payload: any;
}
export interface ToggleSidebar {
  type: typeof TOGGLE_SIDEBAR;
}
export interface SetMobile {
  type: typeof SET_MOBILE;
  payload: boolean;
}
export interface ClosePreviousInputs {
  type: typeof CLOSE_PREVIEW_INPUTS;
}
export interface OpenPreviousInputs {
  type: typeof OPEN_PREVIOUS_INPUTS;
  userId: number;
  index: number;
}
export interface Save {
  type: typeof SAVE;
  message: string;
}
export interface SaveSuccess {
  type: typeof SAVE_SUCCESS;
}
export interface Error {
  type: typeof ERROR;
  message: string;
}
export interface RemoveError {
  type: typeof REMOVE_ERROR;
}
export interface OpenWarmupCooldownDetailsDialog {
  type: typeof OPEN_WARMUP_COOLDOWN_DETAILS_DIALOG;
  payload: any;
}
export interface OpenAddWarmupDialog {
  type: typeof OPEN_ADD_WARMUP_DIALOG;
  payload: any;
}
export interface OpenAddCooldownDialog {
  type: typeof OPEN_ADD_COOLDOWN_DIALOG;
  payload: any;
}
export interface OpenCreateWarmupDialog {
  type: typeof OPEN_CREATE_WARMUP_DIALOG;
  payload: any;
}
export interface OpenCreateCooldownDialog {
  type: typeof OPEN_CREATE_COOLDOWN_DIALOG;
  payload: any;
}
export interface OpenSlopeGraphDialog {
  type: typeof OPEN_SLOPE_GRAPH_DIALOG;
  payload: any;
}
export interface OpenAddClientsToProgramDialog {
  type: typeof OPEN_ADD_CLIENTS_TO_PROGRAM_DIALOG;
  payload: any;
}
export interface ToggleCalculator {
  type: typeof TOGGLE_CALCULATOR;
}

export type AppActions = ClosePrograms |OpenCreateProductDialog | CloseProgramsSuccess | OpenRepeatWorkoutDialog | OpenCreateWorkoutDialog | OpenCreateWarmupDialog | OpenCreateCooldownDialog | SetDialogChildrenSuccess | ToggleSidebar | SetMobile | ClosePreviousInputs | OpenPreviousInputs | Save | SaveSuccess | Error | RemoveError | ToggleCalculator;
