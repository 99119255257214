import { Organisation } from "../../tsUtils/organisationTypes";

export const actionTypes = {
  GET_ORGANISATIONS: '[Organisations] Get Organisations',
  GET_ORGANISATIONS_SUCCESS: '[Organisations] Get Organisations Success',
  GET_ORGANISATIONS_FAIL: '[Organisations] Get Organisations Failure',

  CREATE_ORGANISATION: '[Organisations] Create Organisation',
  CREATE_ORGANISATION_SUCCESS: '[Organisations] Create Organisation Success',
  CREATE_ORGANISATION_FAIL: '[Organisations] Create Organisation Failure',

  UPDATE_ORGANISATION: '[Organisations] Update Organisation',
  UPDATE_ORGANISATION_SUCCESS: '[Organisations] Update Organisation Success',
  UPDATE_ORGANISATION_FAIL: '[Organisations] Update Organisation Failure',

  DELETE_ORGANISATION: '[Organisations] Delete Organisation',
  DELETE_ORGANISATION_SUCCESS: '[Organisations] Delete Organisation Success',
  DELETE_ORGANISATION_FAIL: '[Organisations] Delete Organisation Failure',

  SET_SELECTED_ORGANISATION: '[Organisations] Set Selected Organisation',
  SET_SELECTED_ORGANISATION_SUCCESS: '[Organisations] Set Selected Organisation Success',

  GET_ORGANISATION_USERS: '[Organisations] Get Organisation Users',
  GET_ORGANISATION_USERS_SUCCESS: '[Organisations] Get Organisation Users Success',
  GET_ORGANISATION_USERS_FAIL: '[Organisations] Get Organisation Users Failure',

  GET_COACHES: '[Organisations] Get Coaches',
  GET_COACHES_SUCCESS: '[Organisations] Get Coaches Success',
  GET_COACHES_FAIL: '[Organisations] Get Coaches Failure',
}

export const organisationActions = {
  getOrganisations: (query: string) => {
    return {
      type: actionTypes.GET_ORGANISATIONS,
      query,
    };
  },
  createOrganisation: (organisation: Organisation) => {
    return {
      type: actionTypes.CREATE_ORGANISATION,
      organisation,
    };
  },
  updateOrganisation: (organisation: Organisation) => {
    return {
      type: actionTypes.UPDATE_ORGANISATION,
      organisation,
    };
  },
  deleteOrganisation: (organisation: Organisation) => {
    return {
      type: actionTypes.DELETE_ORGANISATION,
      organisation,
    };
  },
  setSelectedOrganisation: (id: number) => {
    return {
      type: actionTypes.SET_SELECTED_ORGANISATION,
      id,
    };
  },
  getOrganisationUsers: (organisation: Organisation) => {
    return {
      type: actionTypes.GET_ORGANISATION_USERS,
      organisation,
    };
  },
  getCoaches: () => {
    return {
      type: actionTypes.GET_COACHES,
    };
  }
}