import React, { Component } from "react";
import CalendarComponent from "./containers/CalendarComponent";
import '../../style/Coach/Schedule.scss';
import BaseDialog from "../programs/dialogs/BaseDialog";
import AddProgramEvents from "./forms/AddProgramEvents";
import { Add } from "@mui/icons-material";

interface ScheduleProps {
  events_path: string;
  readonly: boolean;
}

function Schedule({events_path, readonly}: ScheduleProps) {
  return (
    <div className="schedule-cont">
      {!readonly && (
      <div style={{width: '100%', display: 'flex', height: '50px', justifyContent: 'flex-end', alignItems: "center"}}>
        <BaseDialog buttonText="Add Program" icon={<Add />} variant="text" buttonWidth={180}>
         <AddProgramEvents />
        </BaseDialog>
      </div>
      )}
      <CalendarComponent readonly={readonly}></CalendarComponent>
    </div>
  );
}

export default Schedule;
