import { compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import { combineReducers } from 'redux';
import { programSlice } from '../+store/programs/reducers';
import authReducer from './auth/reducers';
import { definitions } from '../+store/definitions';
import { clientSlice } from '../+store/clients/reducers';
import appReducer from './app/reducer';
import accountReducer from '../+store/account/reducers';
import { workoutSlice } from '../+store/workouts/reducers';
import { sessionSlice } from '../+store/sessions/reducers';
import { exerciseCardSlice } from '../+store/exerciseCards/reducers';
import { eventSlice } from '../+store/events/reducers';
import { templateSlice } from '../+store/templates/reducers';
import { AssessmentSlice } from '../+store/assessments/reducers';
import { OrganisationSlice } from '../+store/organisations/reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const preloadedState = {};
const sagaMiddleware = createSagaMiddleware();
// const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [sagaMiddleware];

const rootReducer = combineReducers({
  auth: authReducer,
  programs: programSlice.reducer,
  workouts: workoutSlice.reducer,
  sessions: sessionSlice.reducer,
  exercise_cards: exerciseCardSlice.reducer,
  definitions,
  clients: clientSlice.reducer,
  app: appReducer,
  events: eventSlice.reducer,
  templates: templateSlice.reducer,
  account: accountReducer,
  assessments: AssessmentSlice.reducer,
  organisations: OrganisationSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [...middleware],
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
});

sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
