import { Organisation } from '../../tsUtils';
import * as paths from './paths';

export async function listOrganisations(): Promise<Response> {
  let endpoint = `${paths.ORGANISATION}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  });
}

export async function createOrganisation(organisation: Organisation): Promise<Response> {
  let endpoint = `${paths.ORGANISATION}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(organisation),
  });
}

export async function retrieveOrganisation(pk: number): Promise<Response> {
  let endpoint = `${paths.ORGANISATION}/${pk}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  });
}

export async function updateOrganisation(pk: number, organisation: any): Promise<Response> {
  let endpoint = `${paths.ORGANISATION}/${pk}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(organisation),
  });
}

export async function deleteOrganisation(pk: number): Promise<Response> {
  let endpoint = `${paths.ORGANISATION}/${pk}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
  });
}

export async function addAdmin(pk: number, userId: number): Promise<Response> {
  let endpoint = `${paths.ORGANISATION}/${pk}/add_admin/${userId}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
  });
}

export async function removeAdmin(pk: number, userId: number): Promise<Response> {
  let endpoint = `${paths.ORGANISATION}/${pk}/remove_admin/${userId}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
  });
}

export async function changeAccountOwner(pk: number, userId: number): Promise<Response> {
  let endpoint = `${paths.ORGANISATION}/${pk}/change_owner/${userId}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
  });
}

export async function getCoaches(pk: number): Promise<Response> {
  let endpoint = `${paths.COACHES}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  });
}
