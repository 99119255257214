import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { Organisation, OrganisationDetails} from "../../tsUtils/organisationTypes";
import { set } from "lodash";
import { ListUserInterface } from "../../tsUtils";

export interface OrganisationState extends EntityState<Organisation> {
  loading: boolean;
  error: string | null;
  selectedOrganisation: OrganisationDetails | null;
  coaches: ListUserInterface[] | null;
  selected_coach: ListUserInterface | null;
}

const organisationAdapter = createEntityAdapter<Organisation>();

const initialState: OrganisationState = organisationAdapter.getInitialState({
  loading: false,
  error: null,
  selectedOrganisation: null,
  coaches: null,
  selected_coach: null
});

export const OrganisationSlice = createSlice({
  name: "organisations",
  initialState: initialState,
  reducers: {
    addOrganisation: ( state, action) => organisationAdapter.addOne(state, action.payload),
    addOrganisations: (state, action) => organisationAdapter.addMany(state, action.payload),
    updateOrganisation: (state, action) => organisationAdapter.updateOne(state, action.payload),
    deleteOrganisation: (state, action) => organisationAdapter.removeOne(state, action.payload),
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSelectedOrganisation: (state, action) => {
      state.selectedOrganisation = action.payload;
    },
    getCoachesSuccess: (state, action) => {
      state.coaches = action.payload
    },
    setSelectedCoach: (state, action) => {
      state.selected_coach = action.payload
    }
  }
});

export const {
  addOrganisation,
  addOrganisations,
  updateOrganisation,
  deleteOrganisation,
  setLoading,
  setError,
  setSelectedOrganisation,
  getCoachesSuccess,
  setSelectedCoach
} = OrganisationSlice.actions;

export const organisationSelectors = organisationAdapter.getSelectors();
