import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Typography } from '@mui/material';

const orgTypeTeam = [
  'UNIVERSITY',
  'SPORTS_TEAM',
  'SPORTS_ORG',
]

export interface ClientProps {}

const ClientStyle = {
  container: {
    padding: '.5rem',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gap: '.5rem',
  },
  details: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  detailsItem: {
    minWidth: '80px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left',
  },
  tableRow: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  tableCell: {
    padding: '10px',
    minWidth: '150px',
    alignContent: 'start',
  },
  clickableRow: {
    cursor: 'pointer',
  },
};

export default function OrganisationDetail(props: ClientProps) {
  const organisation = useSelector((state: RootState) => state.organisations.selectedOrganisation);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const dispatch = useDispatch();

  return (
    <div style={ClientStyle.container}>
      <div style={ClientStyle.main}>
        <div className="card">
          <Typography variant='button' fontSize='x-large'>{organisation?.name}</Typography>
          <div style={ClientStyle.details as React.CSSProperties}>
            <div style={ClientStyle.detailsItem}>
              <Typography variant='button'><strong>Email:</strong></Typography>
              <Typography variant='button'>{organisation?.email}</Typography>
            </div>
            <div style={ClientStyle.detailsItem}>
              <Typography variant='button'><strong>Phone Number:</strong></Typography>
              <Typography variant='button'>{organisation?.phone_number ?? '-'}</Typography>
            </div>
            <div style={ClientStyle.detailsItem}>
              <Typography variant='button'><strong>Address 1:</strong></Typography>
              <Typography variant='button'>{organisation?.address_1 ?? '-'}</Typography>
            </div>
            <div style={ClientStyle.detailsItem}>
              <Typography variant='button'><strong>Address 2:</strong></Typography>
              <Typography variant='button'>{organisation?.address_2 ?? '-'}</Typography>
            </div>
            <div style={ClientStyle.detailsItem}>
              <Typography variant='button'><strong>Valid Through:</strong></Typography>
              <Typography variant='button'>{organisation?.valid_through ?? '-'}</Typography>
            </div>
          </div>
        </div>
        <div style={{ overflowX: 'auto' }} className="card">
          <Typography variant='button' fontSize='x-large'>Clients</Typography>
          <table style={ClientStyle.table as React.CSSProperties}>
            <thead style={ClientStyle.tableHeader as React.CSSProperties}>
              <tr>
                <th style={ClientStyle.tableCell}><Typography variant='button'>Name</Typography></th>
                <th style={ClientStyle.tableCell}><Typography variant='button'>Email</Typography></th>
              </tr>
            </thead>
            <tbody>
              {organisation?.clients?.map((client, index) => (
                <tr key={index} style={ClientStyle.tableRow}>
                  <td style={ClientStyle.tableCell}><Typography variant='button'>{client.first_name} {client.last_name}</Typography></td>
                  <td style={ClientStyle.tableCell}><Typography variant='button'>{client.email}</Typography></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ overflowX: 'auto' }} className="card">
          <Typography variant='button' fontSize='x-large'>Coaches</Typography>
          <table style={ClientStyle.table as React.CSSProperties}>
            <thead style={ClientStyle.tableHeader as React.CSSProperties}>
              <tr>
                <th style={ClientStyle.tableCell}><Typography variant='button'>Name</Typography></th>
                <th style={ClientStyle.tableCell}><Typography variant='button'>Email</Typography></th>
              </tr>
            </thead>
            <tbody>
              {organisation?.coaches?.map((coach, index) => (
                <tr key={index} style={ClientStyle.tableRow}>
                  <td style={ClientStyle.tableCell}><Typography variant='button'>{coach.first_name} {coach.last_name}</Typography></td>
                  <td style={ClientStyle.tableCell}><Typography variant='button'>{coach.email}</Typography></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ overflowX: 'auto' }} className="card">
          <Typography variant='button' fontSize='x-large'>{organisation && orgTypeTeam.includes(organisation?.org_type) ? 'Teams' : 'Locations'}</Typography>
          <table style={ClientStyle.table as React.CSSProperties}>
            <thead style={ClientStyle.tableHeader as React.CSSProperties}>
              <tr>
                <th style={ClientStyle.tableCell}><Typography variant='button'>Name</Typography></th>
                <th style={ClientStyle.tableCell}><Typography variant='button'>Managers</Typography></th>
              </tr>
            </thead>
            <tbody>
              {organisation?.clubs.map((club, index) => (
                <tr key={index} style={ClientStyle.tableRow}>
                  <td style={ClientStyle.tableCell}><Typography variant='button'>{club.name}</Typography></td>
                  <td style={ClientStyle.tableCell}>
                    {club.club_managers?.map((manager, index) => (
                      <Typography key={index} variant='button'>{manager.first_name} {manager.last_name}</Typography>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
