import * as paths from './paths';
import { getAuthHeader } from '../../coachApp/services/paths';

export async function getClientAssessments(query: string): Promise<Response> {
  let endpoint = `${paths.CLIENTASSESSMENTS}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  })
}