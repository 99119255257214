import { put, takeLatest, SagaReturnType, call } from 'redux-saga/effects';
import * as types from './types';
import { actionTypes } from './actionTypes';
import { assessmentLoading, getAssessmentsSuccess, assessmentFail, updateAssessmentSuccess, createAssessmentSuccess, getAssessmentResultsSuccess, createAssessmentResultSuccess, deleteAssessmentSuccess, deleteAssessmentResultSuccess, updateAssessmentResultSuccess, getAssessmentDetailsSuccess, assessmentLoaded } from './reducers';
import { saved, error, closeDialogs } from '../../store/app/actions';
import * as testAPI from '../../coachApp/services/assessmentAPI';
import { SuccessResponse } from '../../tsUtils/responseType';
import { Assessment, AssessmentResult } from '../../tsUtils/assessmentTypes';
import { getClientAssessments } from '../../clientApp/services/assessmentsApi';


export function* getAssessmentsSaga({ query }: types.GetAssessments) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.getAssessments> = yield testAPI.getAssessments();
    if (response.ok) {
      const data: SuccessResponse<Assessment[]> = yield response.json();
      yield put(getAssessmentsSuccess(data.data));
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* getAssessmentSaga({ id }: types.GetAssessment) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.getAssessment> = yield testAPI.getAssessment(id);
    if (response.ok) {
      const data: SuccessResponse<Assessment & {results: AssessmentResult[]}> = yield response.json();
      yield put(getAssessmentDetailsSuccess(data.data));
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* updateAssessmentSaga({ assessment }: types.UpdateAssessmentRequest) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.updateAssessment> = yield testAPI.updateAssessment(assessment, assessment.id!);
    if (response.ok) {
      const data: SuccessResponse<Assessment> = yield response.json();
      yield put(updateAssessmentSuccess(data.data));
      yield put(saved(data.message));
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* createAssessmentSaga({ assessment, navigate }: types.CreateAssessmentRequest) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.createAssessment> = yield testAPI.createAssessment(assessment);
    if (response.ok) {
      const data: SuccessResponse<Assessment> = yield response.json();
      yield put(createAssessmentSuccess(data.data));
      yield put(closeDialogs());
      yield put(saved(data.message));
      yield call(navigate, `/coach/assessments/${data.data?.id}`);
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* getAssessmentResultsSaga({ assessment_id }: types.GetAssessmentResults) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.getAssessmentResults> = yield testAPI.getAssessmentResults(assessment_id);
    if (response.ok) {
      const data: SuccessResponse<AssessmentResult[]> = yield response.json();
      yield put(getAssessmentResultsSuccess({results: data.data, assessment_id})); 
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* createAssessmentResultSaga({ assessmentResult }: types.CreateAssessmentResultRequest) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.createAssessmentResult> = yield testAPI.createAssessmentResult(assessmentResult);
    if (response.ok) {
      const data: SuccessResponse<AssessmentResult> = yield response.json();
      yield put(saved(data.message));
      yield put(createAssessmentResultSuccess(data.data));
      yield put(closeDialogs());
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* deleteAssessmentSaga({ id }: types.DeleteAssessmentRequest) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.deleteAssessment> = yield testAPI.deleteAssessment(id);
    if (response.ok) {
      const data: SuccessResponse<Assessment> = yield response.json();
      yield put(deleteAssessmentSuccess(id));
      yield put(saved(data.message));
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* deleteAssessmentResultSaga({ id }: types.DeleteAssessmentResultRequest) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.deleteAssessmentResult> = yield testAPI.deleteAssessmentResult(id);
    if (response.ok) {
      const data: SuccessResponse<AssessmentResult> = yield response.json();
      yield put(deleteAssessmentResultSuccess(id));
      yield put(saved(data.message));
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* updateAssessmentResultSaga({ assessmentResult }: types.UpdateAssessmentResultRequest) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof testAPI.updateAssessmentResult> = yield testAPI.updateAssessmentResult(assessmentResult);
    if (response.ok) {
      const data: SuccessResponse<AssessmentResult> = yield response.json();
      yield put(updateAssessmentResultSuccess(data.data));
      yield put(saved(data.message));
      yield put(closeDialogs());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* getClientAssessmentsSaga({ query }: types.GetAssessments) {
  try {
    yield put(assessmentLoading());
    const response: SagaReturnType<typeof getClientAssessments> = yield getClientAssessments(query);
    if (response.ok) {
      const data: SuccessResponse<Assessment[]> = yield response.json();
      yield put(getAssessmentsSuccess(data.data));
      yield put(assessmentLoaded());
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(assessmentFail(e));
  }
}

export function* assessmentSaga() {
  yield takeLatest(actionTypes.GET_ASSESSMENTS, getAssessmentsSaga);
  yield takeLatest(actionTypes.GET_ASSESSMENT, getAssessmentSaga);
  yield takeLatest(actionTypes.UPDATE_ASSESSMENT_REQUEST, updateAssessmentSaga);
  yield takeLatest(actionTypes.CREATE_ASSESSMENT_REQUEST, createAssessmentSaga);
  yield takeLatest(actionTypes.DELETE_ASSESSMENT_REQUEST, deleteAssessmentSaga);
  yield takeLatest(actionTypes.CREATE_ASSESSMENT_RESULT_REQUEST, createAssessmentResultSaga);
  yield takeLatest(actionTypes.DELETE_ASSESSMENT_RESULT_REQUEST, deleteAssessmentResultSaga);
  yield takeLatest(actionTypes.UPDATE_ASSESSMENT_RESULT_REQUEST, updateAssessmentResultSaga);
  yield takeLatest(actionTypes.GET_CLIENT_ASSESSMENTS, getClientAssessmentsSaga);
}

