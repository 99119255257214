import React, { useEffect } from 'react';
import { EventDetails, EventType, event_typeIcon, event_typeName } from '../../../tsUtils/eventTypes';
import {
  Select,
  TextField,
  Typography,
  Icon,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
} from '@mui/material';
import { ColourPicker } from '../../../common/ColourPicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent } from '../../../+store/events/actionTypes';
import { RootState } from '../../../store/store';

const style: any = {
  width: '100%',
  height: '100%',
  minHeight: '450px',
  minWidth: '350px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
  padding: '1rem',
};

const gridItem = {
  display: 'flex',
  alignItems: 'center',
};

interface EventModelProps {
  date?: Date;
  start?: Date;
  end?: Date;
  open: boolean;
  onClose: () => void;
};

export default function CreateEventModal(props: EventModelProps) {
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const dispatch = useDispatch();
  const userID = useSelector((state: RootState) => state.auth.user?.id);
  const { control, handleSubmit, reset } = useForm<EventDetails>({
    defaultValues: new EventDetails({
      start: props.start ? props.start.toISOString() : props.date?.toISOString(),
      end: props.end ? props.end.toISOString() : new Date().toISOString(),
      organiser: userID,
      creator: userID,
    }),
  });

  useEffect(() => {
    if (props.date) {
      const end = new Date(props.date);
      end?.setMinutes(props.date?.getMinutes() + 30);
      reset(
        new EventDetails({
          start: props.date?.toISOString(),
          end: end?.toISOString(),
          organiser: userID,
          creator: userID,
        }),
      );
    }
  }, [props.date]);
  useEffect(() => {
    reset(
      new EventDetails({
        start: props.start?.toISOString(),
        end: props.end?.toISOString(),
        organiser: userID,
        creator: userID,
      }),
    );
  }, [props.end]);
  const event_typeOptions = Object.values(EventType).map(
    type =>
      event_typeName(type as EventType) && (
        <MenuItem key={type} value={type}>
          <Icon>{event_typeIcon(type as EventType)}</Icon> {event_typeName(type as EventType)}
        </MenuItem>
      ),
  );
  function handleSave() {
    handleSubmit(create)();
  }

  function create(formData: EventDetails) {
    dispatch(createEvent(formData));
    props.onClose();
  }
  return (
    <Dialog open={props.open} fullScreen={isMobile} onClose={props.onClose}>
      <form style={style} >
        <Typography variant="h5" style={{ textAlign: 'left', width: '100%' }} component="div">
          Create Event
        </Typography>

        <div style={gridItem}>
          <Controller
            control={control}
            name="title"
            
            render={({ field }) => (
              <TextField
                {...field}
                label="Title"
                required
                variant="filled"
                sx={{ width: '260px' }}
              ></TextField>
            )}
          />
        </div>
        <div style={gridItem}>
          <Controller
            name="event_type"
            control={control}
            render={({ field }) => (
              <FormControl sx={{ width: '260px' }}>
                <InputLabel>Event Type</InputLabel>
                <Select
                  {...field}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  
                  label="Event Type"
                  variant="filled"
                >
                  {event_typeOptions}
                </Select>
              </FormControl>
            )}
          />
        </div>
        
        <div style={gridItem}>
          <Controller
            name="start"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                label="Start"
                {...field}
                renderInput={(params: any) => (
                  <TextField {...params}  variant="filled" sx={{ width: '260px' }} />
                )}
              />
            )}
          />
        </div>
        <div style={gridItem}>
          <Controller
            name="end"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                label="End"
                {...field}
                renderInput={(params: any) => (
                  <TextField {...params}  variant="filled" sx={{ width: '260px' }} />
                )}
              />
            )}
          />
        </div>
        <div style={gridItem}>
          <Controller
            name="backgroundColor"
            control={control}
            render={({ field }) => (
              <ColourPicker changeColour={field.onChange} colour={field.value} />
            )}
          />
        </div>

        <div style={{ width: '100%', display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
        <Button onClick={props.onClose} color="info" sx={{ width: '90px' }} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => handleSave()}
            color="primary"
            fullWidth={false}
            variant="contained"
            sx={{ width: '90px' }}
          >
            Create
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
