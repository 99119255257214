import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Navigate, Link } from 'react-router-dom';
import * as actions from '../store/auth/actions';
import { RootState } from '../store/store';
import '../style/Unauth/unauthMain.scss';
import { CopyRightFooter } from '../common/CopyRightFooter';

export interface LoginForm {
  username: string;
  password: string;
}

interface Props {}

function NormalLoginForm(props: Props) {
  const dispatch = useDispatch();

  const [errorMessage, seterrorMessage] = useState('');

  const error = useSelector((state: RootState) => state.auth.error);

  const user = useSelector((state: RootState) => state.auth.user);

  const loading = useSelector((state: RootState) => state.auth.loading);

  const onFinish = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      username: { value: string };
      password: { value: string };
    };
    dispatch(
      actions.login({
        username: target.username.value,
        password: target.password.value,
      }),
    );
    if (error) {
      seterrorMessage(`<p>${error}</p>`);
    }
  };

  if (user) {
    return <Navigate to="/coach/schedule" />;
  } else {
    return (
      <div className="unauthmain">
        {loading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={onFinish}>
            <div className="form-item">
              <img style={{ width: 200 }} src="/CleverCoachTrainingDark.png" alt="logo" />
            </div>
            <div className="form-item">
              <Typography component="div" style={{ fontSize: 'xx-large' }} className="formTitle" variant="button">
                Coach Login
              </Typography>
              {errorMessage && errorMessage}
            </div>
            <div className="form-item" style={{ width: '100%' }}>
              <TextField
                required
                name="username"
                id="standard-required"
                color="success"
                label="Username"
                variant="filled"
              />
            </div>
            <div className="form-item" style={{ width: '100%' }}>
              <TextField
                required
                id="standard-password-input"
                name="password"
                label="Password"
                color="success"
                type="password"
                autoComplete="current-password"
                variant="filled"
              />
              <Link
                color="primary"
                reloadDocument
                to={`${process.env.REACT_APP_API_URL}/appauth/reset_password/`}
                style={{ margin: '5px' }}
              >
                Reset Password
              </Link>
              {error && (
                <Typography variant="caption" color="error" component="div">
                  {error}
                </Typography>
              )}
            </div>
            <div className="form-item">
              <Button className="button" color="primary" type="submit" variant="contained">
                Login
              </Button>
              <Typography component="div">Or</Typography>
              <Link color="primary" to="/clientlogin" style={{ margin: '5px' }}>
                Login as Client
              </Link>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default NormalLoginForm;
