import React, { useEffect, useState } from 'react';
import { Drawer, Fab, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { COACH, getAuthHeader } from '../coachApp/services/paths';
import { useDispatch, useSelector } from 'react-redux';
import { StyledInput } from './StyledInput';
import { StyledSelect } from './StyledSelect';
import * as appActions from '../store/app/actions';

enum ResultType {
    weight = 'weight',
    percentage = 'percentage',
    one_rm = 'one_rm',
    velocity = 'velocity',
    velocityWeight = 'velocity_weight',
    velocityOnerm = 'velocity_one_rm',
    velocityPercentage = 'velocity_percentage',
}

const style: any = {
    base: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',

    },
    calculator: {
        maxWidth: 'calc(100vh - 60px)',
        width: '330px',
        height: '100vh',
        zIndex: 1000,
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        justifyContent: 'center',
    },
    open: {
        animationName: `open`,
        animationDuration: '1s',
        animationTimingFunction: 'ease-in-out',
    },
    close: {
        animationName: `close`,
        animationDuration: '1s',
        animationTimingFunction: 'ease-in-out',
        transform: 'translateX(100%)',
    },
    trigger: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1202,
    },
    triggerOpen: {
        position: 'fixed',
        bottom: '20px',
        right: '340px',
        zIndex: 1202,
    },
    inputGrid: {
        display: 'grid',
        gridGap: '15px',
        borderTop: '1px solid #ccc',
        padding: '15px 0px',
        borderBottom: '1px solid #ccc',
    },
    submitButton: {
        height: '55px',
        margin: '0px'
    },
    result: {
        flex: 1,
        textAlign: 'center',
        fontSize: '1.4rem',
        lineHeight: '44px',
        height: '44px',
        border: 'none',
        width: '100%',
        background: '#eee',
        borderRadius: '4px'
    }

}
const exercises = [
    ['Bench Press','bench_press'],
    ['Squat','squat'],
    ['Deadlift','deadlift'],
    ['Military Press','military_press'],
    ['Bench Pull','bench_pull'],
    ['Pull Up','pull_up'], 
]

interface CalculatorProps {
    client?: boolean;
}

export default function Calculator({ client }: CalculatorProps) {

    const [result, setResult] = useState(0);
    const [resultType, setResultType] = useState<ResultType>(ResultType.weight);
    const isMobile = useSelector((state: any) => state.app.isMobile);
    const open = useSelector((state: any) => state.app.calculatorOpen);
    const onSubmit = async (event: any) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const formData: { [key: string]: string } = {};
        new FormData(form).forEach((value, key) => {
            if (value === '') return;
            formData[key] = value.toString();
        });
        const params = new URLSearchParams(formData);
        const response = await fetch(`${form.action}${resultType}?${params.toString()}`, {
            method: form.method,
            headers: getAuthHeader(),
        });
        const json = await response.json();
        setResult(json.result);
    }
    const dispatch = useDispatch();

    function toggleCalculator() {
        dispatch(appActions.toggleCalculator());
    }

    return (
        <div style={style.base}>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={() => toggleCalculator()}
            >
                
                <div className='light' style={{ ...style.calculator }} >
                    <div >
                    </div>
                    <div >
                        <FormControl fullWidth>
                            <FormHelperText >Output Type</FormHelperText>
                            <StyledSelect
                                height='34px'
                                value={resultType}
                                label="Calculate"
                                size="medium"
                                onChange={(event: any) => setResultType(event.target.value)}
                            >
                                <MenuItem value={ResultType.weight}>Weight</MenuItem>
                                <MenuItem value={ResultType.percentage}>Percentage</MenuItem>
                                <MenuItem value={ResultType.one_rm}>One Rep Max</MenuItem>
                                <MenuItem value={ResultType.velocity}>Velocity</MenuItem>
                                <MenuItem value={ResultType.velocityOnerm}>One Rep Max Velocity</MenuItem>
                                <MenuItem value={ResultType.velocityWeight}>Velocity Weight</MenuItem>
                                <MenuItem value={ResultType.velocityPercentage}>Velocity Percentage</MenuItem>
                            </StyledSelect>
                        </FormControl>
                    </div>

                    <form id="calculator_inputs" method="GET" onSubmit={onSubmit} action={COACH + "api/calculate/"} style={style.inputGrid} >

                        {
                            [ResultType.one_rm, ResultType.velocityOnerm].includes(resultType)  &&
                            <StyledInput size="medium" height='34px' autoComplete='off' type="number" name="weight" label="Weight" />
                        }
                        {
                            [
                                ResultType.velocityOnerm, 
                                ResultType.velocityPercentage, 
                                ResultType.velocityWeight,
                                ResultType.velocity,
                            ].includes(resultType) === false &&
                            <StyledInput size="medium" height='34px' autoComplete='off' type="number" name="reps" label="Reps" />
                        }
                        {
                            [
                                ResultType.velocityOnerm, 
                                ResultType.velocityPercentage, 
                                ResultType.velocityWeight,
                                ResultType.velocity,
                            ].includes(resultType) &&
                            <FormControl fullWidth>
                            <InputLabel >Exercise</InputLabel>
                            <StyledSelect
                                label="Exercise"
                                size="medium"
                                name="exercise"
                                height='34px'
                            >
                                {exercises.map((exercise) => (
                                    <MenuItem key={exercise[1]} value={exercise[1]}>{exercise[0]}</MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                        }
                        {
                            [
                                ResultType.velocityOnerm, 
                                ResultType.velocityPercentage, 
                                ResultType.velocityWeight,
                            ].includes(resultType) &&
                            <StyledInput size="medium" height='34px' autoComplete='off' name="velocity" label="Velocity" />
                        }
                        {
                            [ResultType.weight, ResultType.velocity].includes(resultType) &&
                            <StyledInput size="medium" height='34px' autoComplete='off' type="number" name="percentage" label="Percentage" />
                        }
                        {
                            [
                                ResultType.velocityOnerm,
                                ResultType.velocity,
                                ResultType.velocityPercentage,
                                ResultType.percentage,
                                ResultType.one_rm,
                            ].includes(resultType) === false &&
                            <StyledInput size="medium" height='34px' autoComplete='off' type="number" required name="one_rm" label="One Rep Max" />
                        }
                        {
                            [
                                ResultType.velocityOnerm, 
                                ResultType.velocityPercentage, 
                                ResultType.velocityWeight,
                                ResultType.velocity,
                            ].includes(resultType) === false &&
                            <>
                                <StyledInput size="medium" height='34px' autoComplete='off' type="number" name="rpe" label="RPE" />
                                <StyledInput size="medium" height='34px' autoComplete='off' type="number" name="rir" label="RIR" />
                            </>
                        }
                        
                        <button style={style.submitButton} className="button" type="submit" >Submit</button>
                    </form>
                    
                    <div style={{width: '100%'}}>
                        <Typography fontSize="medium" variant="button" component="span">Result:</Typography>
                        <div style={style.result}>{result}{resultType === ResultType.percentage && '%'}</div>
                    </div>
                    
                </div>
            </Drawer>
            { (!client || open) &&
                <Fab color="info" sx={open ? style.triggerOpen : style.trigger} onClick={() => toggleCalculator()}>
                    {open ? (<CloseIcon />) : (<CalculateOutlinedIcon />)}
                </Fab>
            }
        </div>

    )
}